<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>     
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tablaDatos"
                        :tableName="'Aguinaldo / Finiquito'"
                        :url="url"
                        :columns="columns"
                        @loadModalData="loadModalData"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsEmpresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col>    
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo de nómina"
                                        :items="itemsTiposNominas"
                                        item-text="claveDescripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="tipo_nomina_id"
                                        class="pa-0 ma-0"
                                        id="tipoNominaID"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <v-autocomplete
                                        outlined
                                        label="Periodo"
                                        :items="itemsPeriodos"
                                        item-text="numero"
                                        item-value="id"
                                        persistent-hint
                                        v-model="periodo_value"
                                        class="pa-0 ma-0"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Mes de Acumulación"
                                    :items="meses"
                                    item-text="nombre"
                                    item-value="nombre"
                                    persistent-hint
                                    v-model="mes_de_acomulacion">
                                </v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoIngreso">
                                    <span>Rango de fechas de pago</span>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="min_fecha_pago"
                                    label="Del:"
                                    placeholder="dd/mm/aaaa"
                                    ref="MinFechaPago"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="max_fecha_pago"
                                    label="Al:"
                                    placeholder="dd/mm/aaaa"
                                    ref="MaxFechaPago"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.numero}} - {{item.tipo_nomina_descripcion}}</div></td>
                                <td><div>{{item.mes_de_acomulacion}}</div></td>
                                <td>
                                    <div>
                                        {{ dateFormat(item.fecha_de_pago) }}
                                    </div>
                                </td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td><div>{{ formatNum(item.netoPagar) }}</div></td>
                                <td style="min-width:290px!important; max-width:301px!important; width:300px; padding-left:8px; padding-right:8px;">
                                    <div class="tblOpciones align-center">
                                        <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                    v-if="!item.nomina_generada || item.nomina_generada=='null'"
                                                    @click="abrirModalCalcular(item)"
                                                >
                                                    <v-list-item-title>Calcular</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.nomina_generada"
                                                    @click="abrirModalCalcular(item)"
                                                >
                                                    <v-list-item-title>Recalcular</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.nomina_generada && item.requiere_recalcular == false"
                                                    @click="abrirModalTimbre(item)"
                                                >
                                                    <v-list-item-title>Acumular</v-list-item-title>
                                                </v-list-item>

                                                <v-list-item
                                                    v-if="item.nomina_generada && item.requiere_recalcular == false"
                                                    @click="descargarReporteExcel(item)"
                                                >
                                                    <v-list-item-title>Reporte Empleados</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="!item.nomina_generada || item.nomina_generada=='null'"
                                                    @click="abrirModal('update', item)"
                                                >
                                                    <v-list-item-title>Editar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.nomina_id!=null"
                                                    @click="detallePrecalculo(item)"
                                                >
                                                    <v-list-item-title>Detalle del precálculo</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="vista_incidencias(item)"
                                                >
                                                    <v-list-item-title>Complementos</v-list-item-title>
                                                </v-list-item>

                                            </v-list>
                                        </v-menu>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>


                                        <v-tooltip top v-if="item.requiere_recalcular">
                                            <template v-slot:activator="{ on }">
                                                <v-img
                                                    v-on="on"
                                                    class="mr-n5"
                                                    style="margin-bottom:2px"
                                                    max-height="23"
                                                    max-width="23"
                                                    src="/static/svg/signoExclamacion.svg"
                                                ></v-img>
                                            </template>
                                            <span>Los complementos de esta nómina han <br>sido modificados, es necesario recalcular.</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <perfect-scrollbar style="background:#FFF">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoading">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoading" grid-list-md id="contenedor">

                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Clientes" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Clientes"
                                                        :items="itemsClientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        class="pa-0 ma-0"
                                                        required
                                                        :error-messages="errors" 
                                                        :readonly="accion == 'update'?true:false"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>

                                            <template v-if="accion=='add'">
                                                <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="12" md="12" class="py-0">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="itemsEmpresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Empresa"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                    ></v-autocomplete>
                                                </v-col>
                                            </template>
                                                
                                                
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Tipo de nómina" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo de nómina"
                                                        :items="itemsTiposNominas"
                                                        item-text="claveDescripcion"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="obtenerCodigo.tipo_nomina_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                        id="tipoNominaID"
                                                        :readonly="accion == 'update'?true:false"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col  cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <v-container fluid class="py-0">
                                                    <v-radio-group row v-model="obtenerCodigo.tipo_periodo_especial" class="py-0">
                                                        <v-radio
                                                            :disabled="updateAguinaldo"
                                                            label="Aguinaldo"
                                                            value="AGUINALDO"
                                                        ></v-radio>
                                                        <v-radio
                                                            :disabled="updateFiniquito"
                                                            label="Finiquito"
                                                            value="FINIQUITO"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-container>
                                            </v-col>
                                            <v-col v-if="obtenerCodigo.tipo_periodo_especial == 'AGUINALDO'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <v-container fluid class="py-0">
                                                    <v-checkbox class="pa-0 ma-0" v-model="aguinaldoFiniquito.descontar_faltas" label="Descontar faltas"></v-checkbox>
                                                    <v-checkbox class="pa-0 ma-0" v-model="aguinaldoFiniquito.descontar_incapacidades" label="Descontar incapacidades"></v-checkbox>
                                                </v-container>
                                            </v-col>
                                            <v-col v-if="obtenerCodigo.tipo_periodo_especial == 'FINIQUITO'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <v-container fluid class="py-0">
                                                    <v-radio-group row v-model="opcionFiniquito" class="py-0">
                                                        <v-radio
                                                            label="Voluntario"
                                                            value="VOLUNTARIO"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="Despido"
                                                            value="DESPIDO"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-container>
                                                <v-container v-if="opcionFiniquito == 'DESPIDO'" fluid class="py-0">
                                                    <v-radio-group row v-model="opcionDespido" class="py-0">
                                                        <v-radio
                                                            label="Completa (90 días)"
                                                            value="COMPLETA"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="Negociación"
                                                            value="NEGOCIACION"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-container>
                                                <v-container v-if="opcionFiniquito == 'DESPIDO' && opcionDespido == 'NEGOCIACION'" fluid>
                                                    <v-radio-group v-model="opcionDias" class="py-0">
                                                        <v-radio
                                                            label="60 días"
                                                            value="60"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="45 días"
                                                            value="45"
                                                        ></v-radio>
                                                        <div class="d-flex">
                                                            <v-col cols="12" xs="3" sm="3" md="3" class="pa-0">
                                                                <v-radio
                                                                    label="Otro"
                                                                    value="otro"
                                                                ></v-radio>
                                                            </v-col>
                                                            <v-col cols="12" sx="" sm="3" md="3" class="pa-0" > 
                                                                <input type="text" v-mask="'###'" v-model="diasOtro" style="border-bottom: 2px solid #a6a6a6;">
                                                            </v-col>
                                                        </div>
                                                    </v-radio-group>
                                                </v-container>
                                                <v-container fluid class="py-0">
                                                    <div class="py-2 textAguinaldo">Otras acciones</div>
                                                    <v-checkbox v-if="opcionFiniquito == 'DESPIDO'" class="pa-0 ma-0 checkboxVuetify" v-model="agregar_indemnizacion_20_dias" label="Indemnización de 20 días por año"></v-checkbox>
                                                    <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="agregar_sueldos" label="Agregar sueldos"></v-checkbox>
                                                    
                                                    <div class="py-2 textAguinaldo">Aguinaldo</div>
                                                    <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="aguinaldoFiniquito.descontar_faltas" label="Descontar faltas"></v-checkbox>
                                                    <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="aguinaldoFiniquito.descontar_incapacidades" label="Descontar incapacidades"></v-checkbox>
                                                </v-container>
                                            </v-col>
                                            <v-col v-if="agregar_sueldos" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="aguinaldoFiniquito.fecha_inicial"
                                                    label="Fecha inicial"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaInicial"
                                                />
                                            </v-col>
                                            <v-col v-if="agregar_sueldos" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="aguinaldoFiniquito.fecha_final"
                                                    label="Fecha final"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaFinal"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Código" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Código" 
                                                        v-model="aguinaldoFiniquito.numero" 
                                                        :error-messages="errors" 
                                                        required
                                                        readonly
                                                        v-mask="'N########'"
                                                    >    
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Mes de Acumulación" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Mes de Acumulación"
                                                        :items="meses"
                                                        item-text="nombre"
                                                        item-value="nombre"
                                                        persistent-hint
                                                        v-model="aguinaldoFiniquito.mes_de_acomulacion"
                                                        required 
                                                        :error-messages="errors" >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripcion" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Descripción" 
                                                        v-model="aguinaldoFiniquito.descripcion" 
                                                        :error-messages="errors" 
                                                        required
                                                    >                                                    
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="aguinaldoFiniquito.fecha_de_pago"
                                                    label="Fecha de pago"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaPago"
                                                />
                                            </v-col>
                                            <v-col v-if="obtenerCodigo.tipo_periodo_especial == 'FINIQUITO'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="aguinaldoFiniquito.fecha_baja"
                                                    label="Fecha de baja"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaBaja"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </perfect-scrollbar>
                    </v-card>
                </v-dialog>
            </template>
            
            <template class="borde-card">
                <v-dialog v-model="dialogCalculo" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalCalculo"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="formCalcular" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagenCalculo" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModalCalculo }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingCalculo">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingCalculo" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" class="py-0">
                                            <v-autocomplete
                                                v-model="modelo_empleados"
                                                :items="items_empleados"
                                                outlined
                                                item-text="nombre_completo"
                                                item-value="id"
                                                label="Todos los empleados"
                                                multiple
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item
                                                        ripple
                                                        class="py-0 my-0"
                                                        @click="toggleAllEmpledos"
                                                    >
                                                        <v-list-item-action>
                                                            <v-icon :color="modelo_empleados.length > 0 ? 'indigo darken-4' : ''">
                                                                {{ icon }}
                                                            </v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                Seleccionar todos
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-divider class="mt-2"></v-divider>
                                                </template>
                                                <template v-slot:selection="{ item, index }" >
                                                    <v-chip v-if="index === 0">
                                                        <span>
                                                            {{ 
                                                                item.nombre_completo < 9
                                                                ? item.nombre_completo
                                                                : item.nombre_completo.substr(0,8)+"..."
                                                            }}
                                                        </span>
                                                    </v-chip>
                                                    <span
                                                        v-if="index === 1"
                                                        class="grey--text caption ml-1"
                                                    >
                                                        (+{{ modelo_empleados.length - 1 }} otros)
                                                    </span>
                                                </template>
                                                <!-- <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index === 0">
                                                        <span>
                                                            {{
                                                                item.nombre_completo < 9
                                                                ? item.nombre_completo
                                                                : item.nombre_completo.substr(0, 8) + "..."
                                                            }}
                                                        </span>
                                                    </v-chip>
                                                    <span
                                                        v-if="index === 1"
                                                        class="grey--text caption"
                                                    >(+{{ modelo_empleados.length - 1 }} otros)
                                                    </span>
                                                </template> -->
                                                <template v-slot:append-outer>
                                                    <v-tooltip top class="pb-7">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                        </template>
                                                        <span class="pb-7 textTooltip">Al no seleccionar uno o más empleados, <br>
                                                            la solicitud para recalcular se aplicará a todos los empleados.</span>
                                                    </v-tooltip>  
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Periodo" rules="required">
                                                <v-text-field
                                                    outlined
                                                    v-model="periodo_id"
                                                    label="Periodo"
                                                    readonly
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <VuetifyDatePicker
                                                outlined
                                                disabled
                                                rules="required"
                                                v-model="fechaPagoCalculo"
                                                label="Fecha de pago"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaPagoCalculo"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions class="d-flex justify-end card_accion">
                                <button 
                                    class="btnCerrar" 
                                    @click="cerrarModal()" 
                                    :disabled="btnCerrar"
                                >
                                    Cerrar
                                </button>
                                <v-btn 
                                    class="btnGuardar" 
                                    @click="descargarReporte(itemAF, 'calcular')" 
                                    :disabled="invaliBtnGuardar" 
                                    :loading="btnGuardar"
                                >
                                    Guardar
                                </v-btn>
                                <v-btn 
                                    class="btnGuardar" 
                                    @click="consultar(itemAF)" 
                                    :disabled="invalidBtnConsultar" 
                                    :loading="btnConsultar"
                                >
                                    Consultar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogCalculoTabla" persistent :max-width="tamModal" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalCalculoTabla"></v-btn>
                    </template>
                    
                        <v-card class="borde-card">
                            <perfect-scrollbar>
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagenCalculoTabla" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModalCalculoTabla }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text >
                                <div id="padre" v-show="isLoadingCalculoTabla">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingCalculoTabla" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>

                                        <v-row class="pa-0 ma-0 informacionGeneral">
                                            <v-row class="px-9 pt-12 pb-7">
                                                <template v-if="showDatos === 'FINIQUITO'">
                                                    <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                        <p class="informacionGeneralTitulo">Información global</p>
                                                    </v-col> 
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                        <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Tipo de nómina</p>
                                                        <p class="informacionGeneralText">{{periodicidad}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de empleados:</p>
                                                        <p class="informacionGeneralText">{{totalEmpleados}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha de pago:</p>
                                                        <p class="informacionGeneralText">{{dateFormat(periodoFechaPago, 'modal')}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                        <p class="informacionGeneralText">{{mesAcumumlacion}}</p>
                                                    </v-col>
                                                    
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                        <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                        <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de otros pagos:</p>
                                                        <p class="informacionGeneralText">{{formatNum(totalOtrosPagos)}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                        <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                                    </v-col>
                                                </template>
                                                <template v-if="showDatos === 'AGUINALDO'">
                                                     <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                        <p class="informacionGeneralTitulo">Información global</p>
                                                    </v-col> 
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                        <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Tipo de nómina</p>
                                                        <p class="informacionGeneralText">{{periodicidad}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de empleados:</p>
                                                        <p class="informacionGeneralText">{{totalEmpleados}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha de pago:</p>
                                                        <p class="informacionGeneralText">{{dateFormat(periodoFechaPago, 'modal')}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                        <p class="informacionGeneralText">{{mesAcumumlacion}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                        <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                        <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                        <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                        </v-row>
                                        <div class="col-lg-12 pa-0 mt-8">
                                        <v-btn 
                                        v-if="showDatos == 'FINIQUITO'"
                                        color="#1E2245" 
                                        class="btnContrats float-right elevation-0 "
                                        right
                                        rounded 
                                        @click="descargaMasivaFiniquito()">
                                            Descargar todos
                                        </v-btn>
                                        </div>
                                        <simple-table
                                            class="tablaWidth"
                                            :rows="itemsEmpleados"
                                            :columns="headersTableEmpleados"
                                            :perPage="[10, 25, 50, 100]"
                                            shortPagination
                                            :modalTable="true"
                                        >
                                            <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                                <tr :key="item.id" v-for="(item, index) in data">
                                                    <td class="pa-0">
                                                        <expandable-item :image="urlAvatar + item.empleado.foto" :title="item.empleado.nombre_completo" :id="item.empleado.id" :valid="item.nomina_valida" :type="showDatos" @descargar="descargarCartaFiniquito">
                                                            <template slot="content">
                                                                <table class="tbl-child" width="100%">
                                                                    <tbody>
                                                                        <template v-if="showDatos === 'AGUINALDO'">
                                                                            <tr>
                                                                                <td><p class="subtitle">Fecha alta IMSS: </p></td>
                                                                                <td><p class="text">{{formatoFecha(item.empleado.fecha_alta_imss)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Faltas: </p></td>
                                                                                <td><p class="text">{{item.faltas}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Incapacidades: </p></td>
                                                                                <td><p class="text">{{item.incapacidades}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Antiguedad en el año: </p></td>
                                                                                <td><p class="text">{{item.antiguedad_anio}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Días de aguinaldo pagado: </p></td>
                                                                                <td><p class="text">{{item.dias_aguinaldo}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                            </tr>
                                                                            <template v-if="item.itemsPercepciones.length != 0">
                                                                                <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Cantidad</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>

                                                                            <template v-if="item.itemsDeducciones.length != 0">
                                                                                <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones:</td>
                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0">Cantidad</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>

                                                                            <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                <tr v-if="item.itemsDeducciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Otros pagos:</td>
                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0 && item.itemsDeducciones.length == 0">Cantidad</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>

                                                                            <tr>
                                                                                <td><p class="subtitle">Total percepciones: </p></td>
                                                                                <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Total deducciones: </p></td>
                                                                                <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                <td><p class="text">{{formatNum(item.per_exento)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                <td><p class="text">{{formatNum(item.per_gravado)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="py-4" style="color: #1E2245; font-weight: bold;">Neto a recibir: </td>
                                                                                <td class="py-4" style="color: #1E2245; font-weight: bold;">{{formatNum(item.aguinaldo_neto)}}</td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-if="showDatos === 'FINIQUITO'">
                                                                            <tr v-if="item.indemnizacion != null">
                                                                                <td style="width: 50%; border-right:1px solid #EFF1FB; vertical-align: top; padding-left:0px !important">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <td class="pb-2 pt-4" style="color: #1E2245; font-weight: bold; font-size: 14px;">Finiquito</td>
                                                                                        </tr>
                                                                                        <tr><td colspan="2"><v-divider class="my-2" style="border: 1px solid #EFF1FB; width:100%"></v-divider></td></tr>

                                                                                        <tr>
                                                                                            <td><p class="subtitle">Fecha alta IMSS: </p></td>
                                                                                            <td><p class="text">{{formatoFecha(item.empleado.fecha_alta_imss)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Fecha de baja: </p></td>
                                                                                            <td>
                                                                                                <p class="text" v-if="item.empleado.fecha_baja">{{ formatoFecha(item.empleado.fecha_baja)}}</p>
                                                                                                <p class="text" v-else>{{formatoFecha(item.periodo.fecha_baja)}}</p>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                            <td><p class="subtitle">Días de pago: </p></td>
                                                                                            <td><p class="text">{{item.empleado.dias_de_pago}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Días de aguinaldo: </p></td>
                                                                                            <td><p class="text">{{ item.empleado.dias_aguinaldo_finiquito}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Días de vacaciones: </p></td>
                                                                                            <td><p class="text">{{ item.empleado.dias_vacaciones_finiquito}}</p></td>
                                                                                        </tr>
                                                                                        <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                            <td><p class="subtitle">Faltas: </p></td>
                                                                                            <td><p class="text">{{item.empleado.faltas}}</p></td>
                                                                                        </tr>
                                                                                        <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                            <td><p class="subtitle">Incapacidades: </p></td>
                                                                                            <td><p class="text">{{item.empleado.incapacidades}}</p></td>
                                                                                        </tr>

                                                                                        <template v-if="item.itemsPercepciones.length != 0">
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Cantidad</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                                <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        </template>

                                                                                        <template v-if="item.itemsDeducciones.length != 0">
                                                                                            <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones:</td>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0">Cantidad</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                                <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        </template>

                                                                                        <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                            <tr v-if="item.itemsDeducciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Otros pagos:</td>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0 && item.itemsDeducciones.length == 0">Cantidad</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                                <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        </template>

                                                                                        <tr>
                                                                                            <td><p class="subtitle">Total Percepciones: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Total deducciones: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.per_excentas)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.per_grabado)}}</p></td>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <td style="color: #1E2245; font-weight: 600; font-size: 13px;">Total neto: </td>
                                                                                            <td style="color: #1E2245; font-weight: 600; font-size: 13px;">{{formatNum(item.neto_pagar)}}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>

                                                                                <td align="left" style="width: 50%; vertical-align: top;">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <td class="pb-2 pt-4" style="color: #1E2245; font-weight: bold; font-size: 14px;">Indemnización</td>
                                                                                        </tr>
                                                                                        <tr><td colspan="2"><v-divider class="my-2" style="border: 1px solid #EFF1FB; width:100%"></v-divider></td></tr>
                                                                                        
                                                                                        <tr>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Información</td>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Cantidad</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Días: </p></td>
                                                                                            <td><p class="text">{{item.indemnizacion.dias?item.indemnizacion.dias:'N/A'}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Tipo: </p></td>
                                                                                            <td><p class="text">{{item.indemnizacion.tipo_finiquito?item.indemnizacion.tipo_finiquito:'N/A'}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Años de servicio: </p></td>
                                                                                            <td><p class="text">{{item.indemnizacion.anos_servicio}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Último sueldo mensual ordinario: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.indemnizacion.ultimo_sueldo_mensual_ordinario)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Salario diario integrado: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.empleado.sueldo_diario_integrado)}}</p></td>
                                                                                        </tr>

                                                                                        <template v-if="item.itemsPercepcionesIndemnizacion.length != 0">
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIndemnizacion in item.itemsPercepcionesIndemnizacion" :key="itemIndemnizacion.id">
                                                                                                <td><p class="subtitle">{{itemIndemnizacion.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIndemnizacion.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        </template>
                                                                                        
                                                                                        <!-- <tr v-if="item.p_Indemnizacion != 0">
                                                                                            <td><p class="subtitle">Indemnización: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.p_Indemnizacion)}}</p></td>
                                                                                        </tr>
                                                                                        <tr v-if="item.p_PrimaAntiguedad != 0">
                                                                                            <td><p class="subtitle">Prima de antigüedad: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.p_PrimaAntiguedad)}}</p></td>
                                                                                        </tr>
                                                                                        <tr v-if="item.p_IndemnizacionServicio != 0">
                                                                                            <td><p class="subtitle">Indemnización por año de servicio: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.p_IndemnizacionServicio)}}</p></td>
                                                                                        </tr> -->

                                                                                        <template v-if="item.itemsDeduccionesIndemnizacion.length != 0">
                                                                                            <tr v-if="item.itemsPercepcionesIndemnizacion.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIndemnizacion in item.itemsDeduccionesIndemnizacion" :key="itemIndemnizacion.id">
                                                                                                <td><p class="subtitle">{{itemIndemnizacion.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIndemnizacion.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        </template>
                                                                                        <!-- <tr>
                                                                                            <td><p class="subtitle">ISR Indemnización: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.indemnizacion.total_isr)}}</p></td>
                                                                                        </tr> -->

                                                                                        <template v-if="item.itemsOtrosPagosIndemnizacion.length != 0">
                                                                                            <tr v-if="item.itemsDeduccionesIndemnizacion.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Otros pagos:</td>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepcionesIndemnizacion.length == 0 && item.itemsDeduccionesIndemnizacion.length == 0">Cantidad</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIndemnizacion in item.itemsOtrosPagosIndemnizacion" :key="itemIndemnizacion.id">
                                                                                                <td><p class="subtitle">{{itemIndemnizacion.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIndemnizacion.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        </template>


                                                                                        <tr>
                                                                                            <td><p class="subtitle">Total Percepciones: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.indemnizacion.total_percepciones)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Total deducciones: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.indemnizacion.total_deducciones)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.indemnizacion.per_exento)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.indemnizacion.per_gravado)}}</p></td>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <td style="color: #1E2245; font-weight: 600; font-size: 13px;">Total neto: </td>
                                                                                            <td style="color: #1E2245; font-weight: 600; font-size: 13px;">{{formatNum(item.indemnizacion.total_neto)}}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td style="color: #1E2245; font-weight: 600; font-size: 13px;">Neto a pagar: </td>
                                                                                            <td style="color: #1E2245; font-weight: 600; font-size: 13px;"> {{netoPago(item.neto_pagar,item.indemnizacion.total_neto)}}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>

                                                                            <template v-else>
                                                                                <tr>
                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Información</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Fecha alta IMSS: </p></td>
                                                                                    <td><p class="text">{{formatoFecha(item.empleado.fecha_alta_imss)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Fecha de baja: </p></td>
                                                                                    <td>
                                                                                        <p class="text" v-if="item.empleado.fecha_baja">{{ formatoFecha(item.empleado.fecha_baja)}}</p>
                                                                                        <p class="text" v-else>{{formatoFecha(item.periodo.fecha_baja)}}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                    <td><p class="subtitle">Días de pago: </p></td>
                                                                                    <td><p class="text">{{item.empleado.dias_de_pago}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Días de aguinaldo: </p></td>
                                                                                    <td><p class="text">{{ item.empleado.dias_aguinaldo_finiquito}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Días de vacaciones: </p></td>
                                                                                    <td><p class="text">{{ item.empleado.dias_vacaciones_finiquito}}</p></td>
                                                                                </tr>
                                                                                <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                    <td><p class="subtitle">Faltas: </p></td>
                                                                                    <td><p class="text">{{item.empleado.faltas}}</p></td>
                                                                                </tr>
                                                                                <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                    <td><p class="subtitle">Incapacidades: </p></td>
                                                                                    <td><p class="text">{{item.empleado.incapacidades}}</p></td>
                                                                                </tr>

                                                                                <template v-if="item.itemsPercepciones.length != 0">
                                                                                    <!-- <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr> -->
                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                    <tr>
                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Cantidad</td>
                                                                                    </tr>
                                                                                    <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                    </tr>
                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                </template>

                                                                                <template v-if="item.itemsDeducciones.length != 0">
                                                                                    <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                    <tr>
                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones:</td>
                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0">Cantidad</td>
                                                                                    </tr>
                                                                                    <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                    </tr>
                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                </template>

                                                                                <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                    <tr v-if="item.itemsDeducciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                    <tr>
                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Otros pagos:</td>
                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0 && item.itemsDeducciones.length == 0">Cantidad</td>
                                                                                    </tr>
                                                                                    <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                    </tr>
                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                </template>

                                                                                <tr>
                                                                                    <td><p class="subtitle">Total Percepciones: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Total deducciones: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Total otros pagos: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.otros_ingresos)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.per_excentas)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.per_grabado)}}</p></td>
                                                                                </tr>

                                                                                <tr class="mb-4 pb-4" style="">
                                                                                    <td style="color: #1E2245; font-weight: bold; font-size: 13px; padding-bottom:16px; padding-top:16px;">Neto a pagar: </td>
                                                                                    <td style="color: #1E2245; font-weight: bold; font-size: 13px; padding-bottom:16px; padding-top:16px;">{{formatNum(item.neto_pagar)}}</td>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                            </template>
                                                        </expandable-item>

                                                        <hr v-if="index < data.length - 1" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </simple-table>
                                        
                                    </v-row>  
                                </v-container>
                            </v-card-text>
                            </perfect-scrollbar>
                            <v-card-actions class="d-flex justify-end">
                                <button class="btnCerrar" @click="cerrarModalTabla()">Cerrar</button>
                            </v-card-actions>
                        </v-card>
                    
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogDownload" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalDownload"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="justify-center">
                            <div class="headerModal">
                                <img :src="imagenDownload" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModalDownload }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text>
                            <div class="loading">
                                <lottie :options="defaultOptions" :height="150" :width="150"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogTimbre" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalTimbre"></v-btn>
                    </template>
                    <v-card class="borde-card">
                            <v-card-title class="justify-center align-center text-center card_titulo">
                                <div>
                                    <img :src="imagen" class="imgModal pb-5" />
                                    <h2 class="titleModalTimbre pb-5">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <v-container  grid-list-md id="contenedor">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 justify-center align-center text-center textTimbre">
                                        ¿Estás seguro que deseas acumular y timbrar el cálculo? No se podrá realizar ningún movimiento posteriormente.
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="card_accion pt-0">
                                <v-container  grid-list-md class="ma-0 pa-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="acumular('acumular')"
                                        >
                                            Acumular
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 py-2 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="acumular('timbrar')"
                                        >
                                            Acumular y timbrar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pt-2 pb-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#828282'
                                            @click="cerrarModal()"
                                            text
                                        >
                                            Cancelar
                                        </v-btn>
                                    </v-col>
                                </v-container>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>


            <template>
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Timbres requeridos</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Registro patronal</th>
                                                    <th class="errorth">Requeridos</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Disponibles</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresRP.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresRP">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.registro_patronal}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError01">{{error.requeridos}}</div></td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.disponibles}}</div> </td>
                                                        </tr>
                                                        
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import Axios from 'axios';
import Datatable from '@/components/datatable/Datatable';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem from '@/components/ExpandableItem.vue';
import Notify from '@/plugins/notify';
import apiClientes from "@/api/clientes";
import empresaApi from '@/api/empresas';
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiPeriodos from '@/api/nominas/periodos';
import apiTipoNominaSAT from '@/api/nominas/tipoNominaSAT';
import apiPeriodicidad from '@/api/nominas/periodicidadPago';
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import urlImg from '@/plugins/Urlimagen';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';
import LoadingCliker from '@/components/LoadingCliker';
import apiIncidencias from '@/api/nominas/incidencias';

export default {
    components: {
        'data-table': Datatable,
        "simple-table": DatatableSimple,
        ExpandableItem,
        VuetifyDatePicker,
        'lottie': Lottie,
        'loading-cliker':LoadingCliker,
    },
    data() {
        return {
            url: "periodos/aguinaldo",
            datosLogin      : null,
            rol             : null,
            columns         : [
                {
                    label: 'Código',
                    name: 'numero',
                    filterable: false
                },
                {
                    label: 'Mes Acumulación',
                    name: 'mes_de_acomulacion',
                    filterable: false
                },
                {
                    label: 'Fecha Pago',
                    name: 'fecha_de_pago',
                    filterable: false
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: false
                },
                {
                    label: 'Importe total',
                    name: 'importeTotal',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align:'center',
                    filterable: false
                }
            ],
            filters         : {
                activo    :true,
                paginate  :true,
                order     :"DESC"
            },
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            periodoIncidencias  : {},
            aguinaldoFiniquito  : {
                id                      : null,
                tipo_nomina_id          : null,
                periodicidad_id         : null,
                tipo_nomina_SAT_id      : null,
                numero                  : null,
                fecha_inicial           : '',
                dias_de_pago            : null,
                fecha_final             : '',
                especial                : true,
                inicio_de_anio          : false,
                fin_de_anio             : false,
                inicio_de_bimestre      : false,
                fin_de_bimestre         : false,
                inicio_de_mes           : false,
                fin_de_mes              : false,
                mes_de_acomulacion      : '',
                descripcion             : '',
                fecha_de_pago           : '',
                numero_de_factura       : '',
                tipo_periodo_especial   : 'AGUINALDO',
                descontar_faltas        : false,
                descontar_incapacidades : false,

                tipo_separacion         : null,
                tipo_liquidacion        : null,
                dias_finiquito          : null,
                fecha_baja              : '',
                nomina_id               :0,
            },
            meses:[ 
                {nombre:'Enero',     id:0},
                {nombre:'Febrero',   id:1}, 
                {nombre:'Marzo',     id:2}, 
                {nombre:'Abril',     id:3}, 
                {nombre:'Mayo',      id:4}, 
                {nombre:'Junio',     id:5},
                {nombre:'Julio',     id:6},
                {nombre:'Agosto',    id:7}, 
                {nombre:'Septiembre',id:8}, 
                {nombre:'Octubre',   id:9}, 
                {nombre:'Noviembre', id:10}, 
                {nombre:'Diciembre', id:11}
            ], 
            userData            : this.$session.get('usuario'),
            accion              : null,
            tituloModal         : null,
            dialog              : false,
            isSaving            : false,
            imagen              : '',
            cliente_value       : null,
            empresa_value       : null,
            periodo_value       : null,
            tipo_nomina_id      : null,
            itemsClientes       : [],
            itemsEmpresas       : [],
            itemsTiposNominas   : [],
            fecha_de_pago       : null,
            min_fecha_pago      : null,
            max_fecha_pago      : null,
            mes_de_acomulacion  : null,
            fecha_inicial       : null,
            fecha_final         : null,
            calendar_inicio     : false,
            calendar_fin        : false,
            calendar_pago       : false,
            codigo              : null,
            obtenerCodigo       : {
                tipo_nomina_id          : null,
                tipo_periodo_especial   : 'AGUINALDO'
            },
            opcionFiniquito     : "VOLUNTARIO",
            opcionDespido       : "COMPLETA",
            opcionDias          : "60",
            diasOtro            : null,
            isLoadingCalculo    : true,
            dialogCalculo       : false,
            imagenCalculo       : '/static/modal/aguinaldoFiniquitoCalculo.png',
            tituloModalCalculo  : 'Cálculo de finiquito/aguinaldo',
            itemAF              : null,
            modelo_empleados    : [],
            items_empleados     : [],
            itemsPeriodos       : [],
            periodo_id          : null,
            urlAvatar           : null,
            calendar_pago_calculo   : false,
            fechaPagoCalculo        : null,
            dialogCalculoTabla      : false,
            imagenCalculoTabla      : '/static/modal/aguinaldoFiniquitoCalculo.png',
            tituloModalCalculoTabla : 'Resultados de la consulta',
            isLoadingCalculoTabla   : true,
            itemsEmpleados          : [],
            headersTableEmpleados   : [
                { label: "Empleados", align:'center',   name: "nombre_completo",    filterable: false },
            ],
            periodicidad_id         : null,
            tipo_nomina_SAT_id      : null,
            showDatos               : "",
            updateFiniquito         : false,
            updateAguinaldo         : false, 
            invalidBtnConsultar     : false,
            invaliBtnGuardar        : false,
            btnConsultar            : false,
            btnGuardar              : false,
            btnCerrar               : false,
            dialogDownload          : false,
            imagenDownload          : "/static/icon/catalogos.png",
            tituloModalDownload     : "Descargando archivo",
            defaultOptions          : { animationData: animationData },
            dialogTimbre            : false,
            itemPrecalculo          : null,
            fraseLoading            : 'Timbrando',
            loadingLottie           : false,
            agregar_indemnizacion_20_dias : false,
            agregar_sueldos         : false,
            tamModal                : "600px",
            totalPercepciones       : 0,
            totalDeducciones        : 0,
            totalOtrosPagos         : 0,
            totalEmpleados          : 0,
            neto                    : 0,
            nombreEmpresa           : null,
            periodicidad            : null,
            periodoFechaPago        : null,
            mesAcumumlacion         : null,
            itemFiniquito           : null,
            dialogErrores           : false,
            erroresRP               : [],
            empresa_valor           : null,

        }
    },
    watch: {
        obtenerCodigo:{
            deep: true,
            handler(obtenerCodigo){
                if(this.accion != 'update'){
                    if(obtenerCodigo.tipo_nomina_id && obtenerCodigo.tipo_periodo_especial != null){
                        this.getCodigo(obtenerCodigo.tipo_nomina_id, obtenerCodigo.tipo_periodo_especial);
                    }
                }
            }
        },
        cliente_value: function(val){
            if(val!= null){
                if(this.accion == 'update'){
                    this.getTiposNominas();
                }
                if(this.accion == 'add'){
                    this.cambiaCliente(val)
                }
            }
        },
        empresa_value: function(val){
            if(val!= null){
                if(this.accion == 'add'){
                    this.getTiposNominas();
                }
            }
        },
        opcionDias: function(val){
            if(val!= null){
               if(val != "otro"){
                   this.diasOtro = null;
               }
            }
        },
        opcionFiniquito: function(val){
            if(val == 'VOLUNTARIO'){
               this.agregar_indemnizacion_20_dias = false;
            }
        },
        'obtenerCodigo.tipo_periodo_especial': function(val){
            if(val == 'AGUINALDO'){
                this.agregar_sueldos = false;
                this.aguinaldoFiniquito.fecha_inicial = null;
                this.aguinaldoFiniquito.fecha_final = null;
                if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                    this.$refs.FechaInicio.resetDate();
                }
                if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                    this.$refs.FechaFinal.resetDate();
                }
            }
        },
    },
    computed: {
        likesAllEmpleados () {
            return this.modelo_empleados.length === this.items_empleados.length
        },
        likesSomeEmpleados () {
            return this.modelo_empleados.length > 0 && !this.likesAllEmpleados
        },
        icon () {
            if (this.likesAllEmpleados) return 'mdi-close-box'
            if (this.likesSomeEmpleados) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },

         /**
         * @method loadModalData Método para cargar datos para el modal de busqueda.
         * @description Este método sirve para inicializar todos los campos antes de abrir el modal de busqueda.
         */
        async loadModalData() {
            await this.resetValues();

            this.accion = 'add';

            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id);
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        setFilters() {
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let tipo_nomina = this.tipo_nomina_id;
            let min_fecha_pago = this.min_fecha_pago;
            let max_fecha_pago = this.max_fecha_pago;
            let mes_de_acomulacion = this.mes_de_acomulacion;
            

            if(this.rol == 'root'){

            }
            else if (this.rol == "admin") {
                this.cliente = this.datosLogin.cliente_id;
            }
            else{
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            }
            
            let filterParams = {
                cliente_id: cliente,
                empresa_id: empresa,
                tipo_nomina_id: tipo_nomina,
                min_fecha_pago: min_fecha_pago,
                max_fecha_pago: max_fecha_pago,
                mes_acumulacion: mes_de_acomulacion
            };

            let defaultParams = {
                activo    :true,
                paginate  :true,
                order     :"DESC"
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;
            this.cliente_value = null;
            this.itemsEmpresas = [];
            this.empresa_value = null;
            this.tipo_nomina_id = null;
            if(this.$refs.MinFechaPago != undefined || this.$refs.MinFechaPago != null){
                this.$refs.MinFechaPago.resetDate();
            }
            if(this.$refs.MaxFechaPago != undefined || this.$refs.MaxFechaPago != null){
                this.$refs.MaxFechaPago.resetDate();
            }
            this.min_fecha_pago = null;
            this.max_fecha_pago = null;
            this.mes_de_acomulacion = null;
        },
        async abrirModal(accion, data = null){
            await this.resetValues();

            this.accion     = accion;
            this.isLoading  = true;
            


            if(accion == "add"){
                this.tituloModal = "Registrar aguinaldo/finiquito";
                this.imagen = "/static/modal/aguinaldoFiniquitoCreate.png"
                this.updateAguinaldo = false;
                this.updateFiniquito =  false;
                
            } else {
                this.tituloModal = "Actualizar aguinaldo/finiquito";
                this.imagen      = "/static/modal/aguinaldoFiniquitoUpdate.png";
                this.cliente_value                              = data.cliente_id;
                this.aguinaldoFiniquito.id                      = data.id;
                this.aguinaldoFiniquito.tipo_nomina_id          = data.tipo_nomina_id;
                this.aguinaldoFiniquito.periodicidad_id         = data.periodicidad_id;
                this.aguinaldoFiniquito.tipo_nomina_SAT_id      = data.tipo_nomina_SAT_id;
                this.aguinaldoFiniquito.numero                  = data.numero;
                this.aguinaldoFiniquito.fecha_inicial           = data.fecha_inicial;
                this.aguinaldoFiniquito.dias_de_pago            = data.dias_de_pago;
                this.aguinaldoFiniquito.fecha_final             = data.fecha_final;
                this.aguinaldoFiniquito.especial                = true;
                this.aguinaldoFiniquito.inicio_de_anio          = false;
                this.aguinaldoFiniquito.fin_de_anio             = false;
                this.aguinaldoFiniquito.inicio_de_bimestre      = false;
                this.aguinaldoFiniquito.fin_de_bimestre         = false;
                this.aguinaldoFiniquito.inicio_de_mes           = false;
                this.aguinaldoFiniquito.fin_de_mes              = false;
                this.aguinaldoFiniquito.mes_de_acomulacion      = data.mes_de_acomulacion;
                this.aguinaldoFiniquito.descripcion             = data.descripcion;
                this.aguinaldoFiniquito.fecha_de_pago           = data.fecha_de_pago;
                this.aguinaldoFiniquito.numero_de_factura       = data.numero_de_factura;
                this.aguinaldoFiniquito.tipo_periodo_especial   = data.tipo_periodo_especial;
                this.aguinaldoFiniquito.descontar_faltas        = data.descontar_faltas;
                this.aguinaldoFiniquito.descontar_incapacidades = data.descontar_incapacidades;
                this.aguinaldoFiniquito.actual                  = data.actual;
                this.aguinaldoFiniquito.anterior_actual         = data.actual;
                this.aguinaldoFiniquito.fecha_baja              = data.fecha_baja;
                this.agregar_indemnizacion_20_dias              = data.agregar_indemnizacion_20_dias;
                this.agregar_sueldos                            = data.agregar_sueldos;

                this.obtenerCodigo.tipo_nomina_id               = data.tipo_nomina_id         
                this.obtenerCodigo.tipo_periodo_especial        = data.tipo_periodo_especial;


                setTimeout(()=>this.$refs.FechaPago.setDate(data.fecha_de_pago),500);
                
                if(data.tipo_periodo_especial === "FINIQUITO"){
                    setTimeout(()=>this.$refs.FechaBaja.setDate(data.fecha_baja),500);
                    this.updateAguinaldo = true;
                    this.updateFiniquito = false;

                    if(data.agregar_sueldos){
                        setTimeout(()=>this.$refs.FechaInicial.setDate(data.fecha_inicial),500);
                        setTimeout(()=>this.$refs.FechaFinal.setDate(data.fecha_final),500);
                    }
                }
                else{
                    this.updateFiniquito = true;
                    this.updateAguinaldo = false;
                }

                this.periodicidad_id = data.periodicidad_id;
                this.tipo_nomina_SAT_id = data.tipo_nomina_SAT_id;

                this.opcionFiniquito        = data.tipo_separacion;
                this.opcionDespido          = data.tipo_liquidacion;
                
                if(data.dias_finiquito == 90 && data.tipo_liquidacion == 'NEGOCIACION'){
                    this.opcionDias = "otro"
                    this.diasOtro = data.dias_finiquito;
                }
                else if(data.dias_finiquito == 60){
                    this.opcionDias = "60";
                }
                else if(data.dias_finiquito == 45){
                    this.opcionDias = "45";
                }
                else{
                    this.opcionDias = "otro"
                    this.diasOtro = data.dias_finiquito;
                }
            }

            if(this.rol == 'root'){
                this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id);
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        vista_incidencias(data = null){
            if(data.periodo_incidencia == null){
                let param = {
                    periodo_id : data.id, 
                    tipo_nomina_id : data.tipo_nomina_id,
                    orden : "SINORDEN",
                    usuario_id:this.datosLogin.id
                }
                apiIncidencias.incidenciasTemporales(param).then(response => {
                    data.periodo_incidencia = response.periodo_incidencia;
                    this.$session.remove("periodoIncidencias");
                    this.$session.set('periodoIncidencias',data);
                    this.$session.set('temporal',true);
                    this.$router.push('/incidenciasNominas');
                })
                .catch(err => {
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                        
                })
            }
            else{
                this.$session.remove("periodoIncidencias");
                this.$session.set('periodoIncidencias',data);
                this.$session.set('temporal',true);
                this.$router.push('/incidenciasNominas');
            }
        },
        async abrirModalCalcular(datos){
            this.isLoadingCalculo   = true;
            this.imagen      = "/static/modal/aguinaldoFiniquitoCalculo.png"
            this.periodo_id = datos.numero;
            this.fechaPagoCalculo   = datos.fecha_de_pago;
            await this.getEmpleados( datos.tipo_nomina_id, datos.tipo_periodo_especial, datos.id);
            this.nombreEmpresa = datos.empresa;
            this.itemAF =  datos;
            this.isLoadingCalculo   = false;

            this.periodoFechaPago   = datos.fecha_de_pago;
            this.mesAcumumlacion    = datos.mes_de_acomulacion;
            this.periodicidad       = datos.periodicidad;

            if(datos.tipo_periodo_especial == "FINIQUITO"){
                if(datos.tipo_separacion == "DESPIDO"){
                    this.tamModal = "1000px";
                }
                else{
                    this.tamModal = "700px";
                }
            }
            else{
                this.tamModal = "700px";
            }
            setTimeout(function(){ document.getElementById("btnModalCalculo").click(); }, 100);
            setTimeout(()=>this.$refs.FechaPagoCalculo.setDate(datos.fecha_de_pago),500);
        },
        cerrarModal() {
            this.dialog     = false;
            this.dialogCalculo  = false;
            this.dialogTimbre = false;
            this.isSaving   = false;
            this.resetValues();
        },
        async resetValues() {
            
            this.aguinaldoFiniquito     = {
                id                      : null,
                tipo_nomina_id          : null,
                periodicidad_id         : null,
                tipo_nomina_SAT_id      : null,
                numero                  : null,
                fecha_inicial           : '',
                dias_de_pago            : null,
                fecha_final             : '',
                especial                : true,
                inicio_de_anio          : false,
                fin_de_anio             : false,
                inicio_de_bimestre      : false,
                fin_de_bimestre         : false,
                inicio_de_mes           : false,
                fin_de_mes              : false,
                mes_de_acomulacion      : '',
                descripcion             : '',
                fecha_de_pago           : '',
                numero_de_factura       : '',
                tipo_periodo_especial   : 'AGUINALDO',
                descontar_faltas        : false,
                descontar_incapacidades : false,

                tipo_separacion         : null,
                tipo_liquidacion        : null,
                dias_finiquito          : null,
                fecha_baja              : '',
                nomina_id               : 0
            };
            if(this.$refs.FechaPago != undefined || this.$refs.FechaPago != null){
                this.$refs.FechaPago.resetDate();
            }
            if(this.$refs.FechaBaja != undefined || this.$refs.FechaBaja != null){
                this.$refs.FechaBaja.resetDate();
            }
            if(this.$refs.FechaPagoCalculo != undefined || this.$refs.FechaPagoCalculo != null){
                this.$refs.FechaPagoCalculo.resetDate();
            }


            this.cliente_value      = null;
            this.empresa_value      = null;
            this.tipo_nomina_id     = null;
            this.itemsClientes      = [];
            this.itemsTiposNominas  = [];
            this.modelo_empleados   = [];
            this.itemsEmpresas      = [];

            this.obtenerCodigo      = {
                tipo_nomina_id          : null,
                tipo_periodo_especial   : 'AGUINALDO'
            };
            this.opcionFiniquito        = "VOLUNTARIO";
            this.opcionDespido          = "COMPLETA";
            this.opcionDias             = "60";
            this.diasOtro               = null;
            this.itemPrecalculo         = null;
            this.loadingLottie          = false;
            this.agregar_indemnizacion_20_dias = false;
            this.agregar_sueldos        = false;
            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar este "+ data.tipo_periodo_especial.toLowerCase() + "?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    if(data.nomina_id == null){
                        apiPeriodos.delete(data.id).then(response => {
                            Notify.Success("Registro eliminado", "El registro ha sido eliminado satisfactoriamente");
                            this.$refs.tablaDatos.getData();
                        })
                        .catch(err => {
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                Notify.ErrorToast(leyenda);
                            }
                        });
                    }
                    else{
                        let params = { nomina_id: data.nomina_id };
                        
                        apiCalculoNomina.deleteAguinaldo(params).then(response => {
                            Notify.Success("Registro eliminado", "El registro ha sido eliminado satisfactoriamente");
                            this.$refs.tablaDatos.getData();
                        })
                        .catch(err => {
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                Notify.ErrorToast(leyenda);
                            }
                        });
                    }
                },
                null,
                true,
                true
            )
        },
        abrirModalTimbre(item){
            this.imagen = "/static/icon/alert_warning.png";
            this.tituloModal = "ADVERTENCIA"
            this.dialogTimbre =  true;
            this.itemPrecalculo = item;
        },
        acumular(accion){
            let self = this;
            if (accion == 'acumular'){
                let param = {nomina_id:self.itemPrecalculo.nomina_id};
                apiCalculoNomina.acumular(param).then(async response => {
                    Notify.Success("Acumulado de nómina", "El cálculo de nómina ha sido acumulado correctamente."); 
                    self.$refs.tablaDatos.getData();
                    self.cerrarModal();
                })
                .catch(err => {
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        self.cerrarModal();
                    }
                });
            }
            else if (accion == 'timbrar'){
                let param = {nomina_id:self.itemPrecalculo.nomina_id};
                apiCalculoNomina.acumularTimbrar(param).then(async response => {
                    if(response.status == 200){
                        self.detalleTimbre(self.itemPrecalculo);
                        self.$refs.tablaDatos.getData();
                    }
                    else if(response.status == 202){
                        self.isLoading =false;
                        self.erroresRP = response.data.items;
                        self.dialogErrores = true;
                    }
                })
                .catch(err => {
                    this.loadingLottie = false;
                    this.isLoading =false;
                    self.cerrarModal();
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            }
        },
        timbrar(data){
            this.fraseLoading = "Timbrando";
            this.loadingLottie = true;
            let param = {nomina_id:data.nomina_id};
            apiCalculoNomina.timbrar(param).then(response => {
                this.detalleTimbre(data);
            })
            .catch(err => {
                this.loadingLottie=false; 
                this.$refs.tablaDatos.getData();
                this.cerrarModal();
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        detalleTimbre(data){
            let nomina = {id: data.nomina_id}
            this.$session.remove("detalleTimbre");
            this.$session.set('detalleTimbre',nomina);
            this.$router.push('/detalleTimbre');
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                this.aguinaldoFiniquito.periodicidad_id = this.periodicidad_id;
                this.aguinaldoFiniquito.tipo_nomina_SAT_id = this.tipo_nomina_SAT_id;
                this.aguinaldoFiniquito.tipo_nomina_id = this.obtenerCodigo.tipo_nomina_id;
                this.aguinaldoFiniquito.tipo_periodo_especial = this.obtenerCodigo.tipo_periodo_especial;
                this.aguinaldoFiniquito.dias_de_pago = 0;

                if(this.agregar_sueldos){
                    this.aguinaldoFiniquito.fecha_inicial = this.aguinaldoFiniquito.fecha_inicial;
                    this.aguinaldoFiniquito.fecha_final = this.aguinaldoFiniquito.fecha_final;
                }
                else{
                    this.aguinaldoFiniquito.fecha_inicial = this.aguinaldoFiniquito.fecha_de_pago;
                    this.aguinaldoFiniquito.fecha_final = this.aguinaldoFiniquito.fecha_de_pago;
                }

                //finiquito
                if(this.obtenerCodigo.tipo_periodo_especial == 'FINIQUITO'){
                    this.aguinaldoFiniquito.tipo_separacion = this.opcionFiniquito;//voluntario o despido

                    this.aguinaldoFiniquito.agregar_indemnizacion_20_dias = this.agregar_indemnizacion_20_dias;
                    this.aguinaldoFiniquito.agregar_sueldos = this.agregar_sueldos;

                    if(this.opcionFiniquito == 'DESPIDO'){
                        this.aguinaldoFiniquito.tipo_liquidacion = this.opcionDespido;//completo o negociacion
                        if( this.opcionDespido == "COMPLETA"){
                            this.aguinaldoFiniquito.dias_finiquito = 90;
                        }
                        else{
                            if(this.opcionDias == "60"){
                                this.aguinaldoFiniquito.dias_finiquito = 60;
                            }
                            else if(this.opcionDias == "45"){
                                this.aguinaldoFiniquito.dias_finiquito = 45;
                            }
                            else{
                                this.aguinaldoFiniquito.dias_finiquito = this.diasOtro;
                            }
                        }
                    }
                }

                if(this.accion === "add") {
                    apiPeriodos.store(this.aguinaldoFiniquito).then((response) => {
                        Notify.Success("Operación exitosa", "El registro se guardó satisfactoriamente.");
                        this.$refs.tablaDatos.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    this.aguinaldoFiniquito.actual
                    apiPeriodos.update(this.aguinaldoFiniquito)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El registro se actualizó satisfactoriamente.");
                        this.$refs.tablaDatos.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch((err) => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        async getClientes(){
            let paramClientes = {
                activo      : true, 
                paginate    : false,
            }
            await apiClientes.getClientes(paramClientes).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los clientes");
            })
        },
        async getTiposNominas(val){
            this.itemsTiposNominas  = [];   
            let param = {
                activo      : true,
                paginate    : false,
            }

            if(this.rol == 'root'){
                param.cliente_id = this.cliente_value;
                param.empresa_id = this.empresa_value;
            }
            else if(this.rol == 'admin'){
                param.cliente_id = this.datosLogin.cliente_id;
                param.empresa_id = this.empresa_value;
            }
            else{
                param.cliente_id = this.datosLogin.cliente_id;
                param.empresa_id = this.datosLogin.empresa_id;
            }

            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        cambiaCliente(id) {
            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response)=>{
                this.itemsEmpresas = response.data;
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        async getCodigo(tipo_nomina_id, tipo){
            let param = {
                tipo_nomina_id  : tipo_nomina_id, 
                tipo    : tipo,
            }
            await apiPeriodos.getNumeroPeriodo(param).then((response) => {
                this.aguinaldoFiniquito.numero = response.codigo;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo consultar el código.");
            })
        },
        async getPeriodicidad(){
            let param = {
                clave       : '99',
                activo      : true,
                paginate    : false,
            }
            await apiPeriodicidad.find(param).then((response) => {
                this.periodicidad_id = response.data[0].id;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo consultar la periodicidad SAT");
            })
        },
        async getTipoNominaSAT(){
            let param = {
                clave       : 'E',
                activo      : true,
                paginate    : false,
            }
            await apiTipoNominaSAT.find(param).then((response) => {
                this.tipo_nomina_SAT_id  = response.data[0].id;                
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo consultar el tipo de nomina SAT");
            })
        },
        listar() {
            let empresa = null;
            let cliente = null;
            let empresas = null;

            if(this.rol == "root"){

            }
            else if(this.rol == "admin"){
                this.filters.cliente_id = this.datosLogin.cliente_id;
            }
            else{
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
            }
        },
        async getEmpleados(id_tipoNomina, tipo, periodo_id) {
            let param = { tipo_nomina_id:id_tipoNomina, tipo: tipo, periodo_id:periodo_id };
            await apiTipoNomina.getEmpleados(param).then((response) => {
                this.items_empleados = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los empleados.");
            })
        },

        descargaMasivaFiniquito(){
            let empleados = [];
            this.itemsEmpleados.forEach(item => {
                empleados.push({empleado_id:item.empleado.id});
            });
            this.descargarCartaFiniquito(empleados);
        },

         /*
        Entrada:
        Salida:
        Descripción: Ejecuta el api calcular-finiquito/descargar para traer el 
                     fromato de carta finiquito y poder descargarlo.
        */
        async descargarCartaFiniquito(empleados) {
                let parametros = {
                    "accion":"RECONSULTAR",
                    "tipo_reporte": "empleados",
                    "tipo_nomina": "FINIQUITO",
                    "periodo_id": this.itemFiniquito.id,
                    "tipo_nomina_id": this.itemFiniquito.tipo_nomina_id,
                    "empleados": empleados
                }
           

            let urlCalculo = "calculo-nomina/calcular-finiquito/descargar";
            
            
            const FileDownload = require("js-file-download"); 
            let nombre;
            nombre = this.itemFiniquito.empresa + "-" + this.itemFiniquito.tipo_nomina + "-" + this.itemFiniquito.numero + ".pdf";
            this.fraseLoading = "Descargando";
            this.loadingLottie = true;
            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    if(this.aguinaldoFiniquito.nomina_id == 0){
                        this.$refs.tablaDatos.getData();
                    }
                    this.cerrarModal();
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false; 
                    this.dialogDownload         = false;
                    this.loadingLottie          = false;
                })
                .catch(async (error) =>{
                    
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false; 
                    this.dialogDownload         = false;
                    this.loadingLottie          = false;

                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar el cálculo");
                console.log(err);
                this.btnGuardar             = false;
                this.invalidBtnConsultar    = false;
                this.btnCerrar              = false;
                this.dialogDownload         = false;
                this.loadingLottie          = false;
                this.cerrarModal();
                this.$refs.tablaDatos.getData();
            }
        },

        async descargarReporte(item, tipo){

            this.aguinaldoFiniquito.nomina_id = 0;
            let formatEmpleados = [];
            if(tipo == 'reporte'){
                this.aguinaldoFiniquito.nomina_id = item.nomina_id;
                this.dialogDownload = true;
            }
            else{
                await this.$refs.formCalcular.validate().then(success => {
                    this.btnGuardar             = true;
                    this.invalidBtnConsultar    = true;
                    this.btnCerrar              = true;
                    if(!success) {
                        this.btnGuardar             = false;
                        this.invalidBtnConsultar    = false;
                        this.btnCerrar              = false;
                        return;
                    }

                    let sefl = this;
                    this.modelo_empleados.forEach( item => {
                        let obj = {"empleado_id":item};
                        formatEmpleados.push(obj);
                    })
                });   
            }


            let parametros = {};

            if(item.nomina_generada){
                parametros = {
                    "accion":"RECALCULAR",
                    "tipo_reporte": "empleados",
                    "tipo_nomina": item.tipo_periodo_especial,
                    "periodo_id": item.id,
                    "tipo_nomina_id": item.tipo_nomina_id,
                    "empleados": formatEmpleados
                }
            }
            else{
                parametros = {
                    "accion":"CALCULAR",
                    "tipo_reporte": "empleados",
                    "tipo_nomina": item.tipo_periodo_especial,
                    "periodo_id": item.id,
                    "tipo_nomina_id": item.tipo_nomina_id,
                    "empleados": formatEmpleados
                }
            }

            let urlCalculo = "";

            if(item.tipo_periodo_especial == 'AGUINALDO'){
                urlCalculo = "calculo-nomina/calcular-aguinaldo"
            }
            else if(item.tipo_periodo_especial == 'FINIQUITO'){
                urlCalculo = "calculo-nomina/calcular-finiquito"
            }
            
            const FileDownload = require("js-file-download"); 
            let nombre;
            nombre = item.empresa + "-" + item.tipo_nomina + "-" + item.numero + ".xlsx";

            if(formatEmpleados.length == 0 && this.items_empleados.length != 0){
                Notify.Alert(
                    "ADVERTENCIA",
                    "¿Estás seguro que deseas realizar esta acción? Se calculará de nuevo a todos los empleados.",
                    "Calcular",
                    () => {
                        try {
                            Vue.axios({
                            method: "POST",
                            responseType: "blob",
                            url: urlCalculo,
                            data: parametros,
                            }).then((response, status, xhr) => {
                                FileDownload(response.data, nombre);
                                if(this.aguinaldoFiniquito.nomina_id == 0){
                                    this.$refs.tablaDatos.getData();
                                }
                                this.cerrarModal();
                                this.btnGuardar             = false;
                                this.invalidBtnConsultar    = false;
                                this.btnCerrar              = false; 
                                this.dialogDownload         = false;
                            })
                            .catch(async (error) =>{
                                this.btnGuardar             = false;
                                this.invalidBtnConsultar    = false;
                                this.btnCerrar              = false; 
                                this.dialogDownload         = false;
                                if (
                                    error.request.responseType === 'blob' &&
                                    error.response.data instanceof Blob &&
                                    error.response.data.type &&
                                    error.response.data.type.toLowerCase().indexOf('json') != -1
                                ){
                                    errorString = JSON.parse(await error.response.data.text());
                                    console.log(errorString)

                                    if(typeof errorString.error === 'string'){
                                        Notify.ErrorToast(errorString.error);
                                    }else{
                                        let error = Object.values(errorString.error);
                                        let leyenda = ``;

                                        for (var i = 0; i < error.length; i++) {
                                            leyenda+= `* ` + error[i] + `\n`;
                                        }
                                        Notify.ErrorToast(leyenda);
                                    }
                                }
                                else{
                                    Notify.ErrorToast("Error");
                                }
                            });
                        } 
                        catch (err) {
                            Notify.ErrorToast("No se pudo realizar el cálculo");
                            console.log(err);
                            this.btnGuardar             = false;
                            this.invalidBtnConsultar    = false;
                            this.btnCerrar              = false;
                            this.dialogDownload         = false;
                            this.cerrarModal();
                            this.$refs.tablaDatos.getData();
                        }
                    },
                    ()=>{
                        this.btnGuardar             = false;
                        this.invalidBtnConsultar    = false;
                        this.btnCerrar              = false;
                    }
                )
            }
            else if(formatEmpleados.length != 0 && this.items_empleados.length != 0){
                try {
                    Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: urlCalculo,
                    data: parametros,
                    }).then((response, status, xhr) => {
                        FileDownload(response.data, nombre);
                        if(this.aguinaldoFiniquito.nomina_id == 0){
                            this.$refs.tablaDatos.getData();
                        }
                        this.cerrarModal();
                        this.btnGuardar             = false;
                        this.invalidBtnConsultar    = false;
                        this.btnCerrar              = false; 
                        this.dialogDownload         = false;
                    })
                    .catch(err =>{
                        console.log(err);
                        this.btnGuardar             = false;
                        this.invalidBtnConsultar    = false;
                        this.btnCerrar              = false; 
                        this.dialogDownload         = false;
                    });
                } 
                catch (err) {
                    Notify.ErrorToast("No se pudo realizar el cálculo");
                    console.log(err);
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false;
                    this.dialogDownload         = false;
                    this.cerrarModal();
                    this.$refs.tablaDatos.getData();
                }
            }
            else{
                this.btnGuardar             = false;
                this.invalidBtnConsultar    = false;
                this.btnCerrar              = false;
            }
        },
        async descargarReporteExcel(item){

            let parametros = {
                nomina_id : item.nomina_id,
                tipo_nomina: item.tipo_periodo_especial
            }

            let urlCalculo = "reportes/nomina"
            const FileDownload = require("js-file-download"); 
            let nombre;
            nombre = item.empresa + "-" + item.tipo_nomina + "-" + item.numero + ".xlsx";

            this.fraseLoading = "Descargando";
            this.loadingLottie = true;

            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    
                    this.cerrarModal();
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false; 
                    this.dialogDownload         = false;
                    this.loadingLottie          = false;
                })
                .catch(async error =>{
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false; 
                    this.dialogDownload         = false;
                    this.loadingLottie          = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    };
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar el cálculo");
                console.log(err);
                this.btnGuardar             = false;
                this.invalidBtnConsultar    = false;
                this.btnCerrar              = false;
                this.dialogDownload         = false;
                this.loadingLottie          = false;
                this.cerrarModal();
            }
        },
        async descargarReporteArchivo(item, tipo){
            this.aguinaldoFiniquito.nomina_id = 0;
            let formatEmpleados = [];
            if(tipo == 'reporte'){
                this.aguinaldoFiniquito.nomina_id = item.nomina_id;
                this.dialogDownload = true;
            }
            else{
                await this.$refs.formCalcular.validate().then(success => {
                    this.btnGuardar             = true;
                    this.invalidBtnConsultar    = true;
                    this.btnCerrar              = true;
                    if(!success) {
                        this.btnGuardar             = false;
                        this.invalidBtnConsultar    = false;
                        this.btnCerrar              = false;
                        return;
                    }
                });   
            }


            let parametros = {};

            if(item.nomina_generada){
                parametros = {
                    "accion":"RECALCULAR",
                    "tipo_reporte": "empleados",
                    "tipo_nomina": item.tipo_periodo_especial,
                    "periodo_id": item.id,
                    "tipo_nomina_id": item.tipo_nomina_id,
                    "empleados": formatEmpleados,
                    "descargar":true
                }
            }
            else{
                parametros = {
                    "accion":"CALCULAR",
                    "tipo_reporte": "empleados",
                    "tipo_nomina": item.tipo_periodo_especial,
                    "periodo_id": item.id,
                    "tipo_nomina_id": item.tipo_nomina_id,
                    "empleados": formatEmpleados
                }
            }

            let urlCalculo = "";

            if(item.tipo_periodo_especial == 'AGUINALDO'){
                urlCalculo = "calculo-nomina/calcular-aguinaldo"
            }
            else if(item.tipo_periodo_especial == 'FINIQUITO'){
                urlCalculo = "calculo-nomina/calcular-finiquito"
            }
            
            const FileDownload = require("js-file-download"); 
            let nombre;
            nombre = item.empresa + "-" + item.tipo_nomina + "-" + item.numero + ".xlsx";
            
            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    if(this.aguinaldoFiniquito.nomina_id == 0){
                        this.$refs.tablaDatos.getData();
                    }
                    this.cerrarModal();
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false; 
                    this.dialogDownload         = false;
                })
                .catch(async (error) =>{
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false; 
                    this.dialogDownload         = false;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar el cálculo");
                console.log(err);
                this.btnGuardar             = false;
                this.invalidBtnConsultar    = false;
                this.btnCerrar              = false;
                this.dialogDownload         = false;
                this.cerrarModal();
                this.$refs.tablaDatos.getData();
            }

        },
        async consultar(item){
            let self = this;
            this.$refs.formCalcular.validate().then(success => {
                this.btnConsultar       = true;
                this.invaliBtnGuardar   = true;
                this.btnCerrar          = true;
                if(!success) {
                    this.btnConsultar       = false;
                    this.invaliBtnGuardar   = false;
                    this.btnCerrar          = false;
                    return;
                }
                let formatEmpleados = [];
                this.isLoadingCalculoTabla = true;
                
                this.modelo_empleados.forEach( item => {
                    let obj = {"empleado_id":item};
                    formatEmpleados.push(obj);
                })

                let parametros = {};
                if(item.nomina_generada){
                    parametros = {
                        "accion":"RECONSULTAR",
                        "tipo_reporte": "empleados",
                        "tipo_nomina": item.tipo_periodo_especial,
                        "periodo_id": item.id,
                        "tipo_nomina_id": item.tipo_nomina_id,
                        "empleados": formatEmpleados
                    }
                }
                else{
                    parametros = {
                        "accion":"CONSULTAR",
                        "tipo_reporte": "empleados",
                        "tipo_nomina": item.tipo_periodo_especial,
                        "periodo_id": item.id,
                        "tipo_nomina_id": item.tipo_nomina_id,
                        "empleados": formatEmpleados
                    }
                }
               
                this.showDatos = item.tipo_periodo_especial;

                if(item.tipo_periodo_especial == 'AGUINALDO'){
                    this.itemFiniquito = null;
                    apiCalculoNomina.calcularAguinaldo(parametros).then((response) => {
                        this.itemsEmpleados = response.data;

                        self.totalEmpleados         = response.data.length;
                        self.totalPercepciones      = response.totales.percepciones;
                        self.totalDeducciones       = response.totales.deducciones;
                        self.neto                   = response.totales.neto;


                        let formatEmpleados = response.data;

                        if(formatEmpleados.length != 0){
                            self.totalEmpleados    = formatEmpleados.length;
                            formatEmpleados.forEach(empleado => {
                                empleado.itemsPercepciones  = [];
                                empleado.itemsDeducciones   = [];
                                empleado.itemsOtrosPagos    = [];
                                empleado.itemsInformativos  = [];
                                empleado.p_IndemnizacionServicio = 0;
                                empleado.p_PrimaAntiguedad  = 0;
                                empleado.p_Indemnizacion    = 0;

                                
                                empleado.incidencias.forEach(incidencia =>{
                                    switch (incidencia.codigo_incidencia.charAt(0)) {
                                        case 'P':
                                            if(incidencia.funcion_nombre == "p_IndemnizacionServicio"){
                                                empleado.p_IndemnizacionServicio = incidencia.total;
                                                this.tamModal = "1000px";
                                            }
                                            else if(incidencia.funcion_nombre == "p_PrimaAntiguedad"){
                                                empleado.p_PrimaAntiguedad  = incidencia.total;
                                                this.tamModal = "1000px";
                                            }
                                            else if(incidencia.funcion_nombre == "p_Indemnizacion"){
                                                empleado.p_Indemnizacion    = incidencia.total;
                                                this.tamModal = "1000px";
                                            }
                                            else{
                                                let complemento = {
                                                    codigo : incidencia.codigo_incidencia,
                                                    nombre : incidencia.nombre_incidencia,
                                                    total  : incidencia.total
                                                }
                                                if(parseFloat(incidencia.total) != 0){
                                                    empleado.itemsPercepciones.push(complemento);
                                                }
                                            }
                                            break;
                                        case 'D':
                                            let complemento2 = {
                                                codigo : incidencia.codigo_incidencia,
                                                nombre : incidencia.nombre_incidencia,
                                                total  : incidencia.total
                                            }
                                            if(parseFloat(incidencia.total) != 0){
                                                empleado.itemsDeducciones.push(complemento2);
                                            }
                                            break;
                                        case 'O':
                                            let complemento3 = {
                                                codigo : incidencia.codigo_incidencia,
                                                nombre : incidencia.nombre_incidencia,
                                                total  : incidencia.total
                                            }
                                            if(parseFloat(incidencia.total) != 0){
                                                empleado.itemsOtrosPagos.push(complemento3);
                                            }
                                            break;  
                                        case 'I':
                                            let complemento4 = {
                                                codigo : incidencia.codigo_incidencia,
                                                nombre : incidencia.nombre_incidencia,
                                                total  : incidencia.total
                                            }
                                            if(parseFloat(incidencia.total) != 0){
                                                empleado.itemsInformativos.push(complemento4);
                                            }
                                            break;  
                                        default:
                                            break;
                                    }  
                                })
                                if(parseFloat(empleado.isr_retener) != 0){
                                    empleado.itemsDeducciones.push({
                                        codigo : 'O999',
                                        nombre : 'ISR',
                                        total  : empleado.isr_retener
                                    });
                                }
                            })
                        }

                        setTimeout(function(){ document.getElementById("btnModalCalculoTabla").click(); }, 100);
                        this.isLoadingCalculoTabla = false;
                        this.btnConsultar       = false;
                        this.invaliBtnGuardar   = false;
                        this.btnCerrar          = false;
                    })
                    .catch (err => {
                        console.log(err);
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                        this.cerrarModalTabla();
                    })
                }
                if(item.tipo_periodo_especial == 'FINIQUITO'){
                    this.itemFiniquito = item;
                    apiCalculoNomina.calcularFiniquito(parametros).then((response) => {
                        self.totalPercepciones       = response.totales.percepciones;
                        self.totalDeducciones        = response.totales.deducciones;
                        self.totalOtrosPagos         = response.totales.otros_pagos;
                        self.neto                    = response.totales.neto;

                        let formatEmpleados = response.data;

                        if(formatEmpleados.length != 0){
                            self.totalEmpleados    = formatEmpleados.length;
                            formatEmpleados.forEach(empleado => {
                                empleado.itemsPercepciones  = [];
                                empleado.itemsDeducciones   = [];
                                empleado.itemsOtrosPagos    = [];
                                empleado.itemsInformativos  = [];
                                empleado.p_IndemnizacionServicio = 0;
                                empleado.p_PrimaAntiguedad  = 0;
                                empleado.p_Indemnizacion    = 0;

                                empleado.itemsPercepcionesIndemnizacion     = [];
                                empleado.itemsDeduccionesIndemnizacion      = [];
                                empleado.itemsOtrosPagosIndemnizacion       = [];
                                empleado.itemsInformativosIndemnizacion     = [];

                                if(parseFloat(empleado.empleado.total_sueldo) != 0){
                                    empleado.itemsPercepciones.push({
                                        codigo : 'P1000',
                                        nombre : 'Sueldo',
                                        total  : empleado.empleado.total_sueldo
                                    });
                                }
                                empleado.incidencias.forEach(incidencia =>{
                                    switch (incidencia.codigo_incidencia.charAt(0)) {
                                        case 'P':
                                            if(incidencia.funcion_nombre == "p_IndemnizacionServicio"){
                                                empleado.p_IndemnizacionServicio = incidencia.total;
                                                this.tamModal = "1000px";
                                            }
                                            else if(incidencia.funcion_nombre == "p_PrimaAntiguedad"){
                                                empleado.p_PrimaAntiguedad  = incidencia.total;
                                                this.tamModal = "1000px";
                                            }
                                            else if(incidencia.funcion_nombre == "p_Indemnizacion"){
                                                empleado.p_Indemnizacion    = incidencia.total;
                                                this.tamModal = "1000px";
                                            }
                                            else{
                                                let complemento = {
                                                    codigo : incidencia.codigo_incidencia,
                                                    nombre : incidencia.nombre_incidencia,
                                                    total  : incidencia.total
                                                }
                                                if(parseFloat(incidencia.total) != 0){
                                                    empleado.itemsPercepciones.push(complemento);
                                                }
                                            }
                                            break;
                                        case 'D':
                                            let complemento2 = {
                                                codigo : incidencia.codigo_incidencia,
                                                nombre : incidencia.nombre_incidencia,
                                                total  : incidencia.total
                                            }
                                            if(parseFloat(incidencia.total) != 0){
                                                empleado.itemsDeducciones.push(complemento2);
                                            }
                                            break;
                                        case 'O':
                                            let complemento3 = {
                                                codigo : incidencia.codigo_incidencia,
                                                nombre : incidencia.nombre_incidencia,
                                                total  : incidencia.total
                                            }
                                            if(parseFloat(incidencia.total) != 0){
                                                empleado.itemsOtrosPagos.push(complemento3);
                                            }
                                            break;  
                                        case 'I':
                                            let complemento4 = {
                                                codigo : incidencia.codigo_incidencia,
                                                nombre : incidencia.nombre_incidencia,
                                                total  : incidencia.total
                                            }
                                            if(parseFloat(incidencia.total) != 0){
                                                empleado.itemsInformativos.push(complemento4);
                                            }
                                            break;  
                                        default:
                                            break;
                                    }  
                                })

                                if(empleado.indemnizacion != null && empleado.indemnizacion.length != 0){
                                    this.tamModal = "1000px";
                                    empleado.indemnizacion.incidencias.forEach(incidenciaIndemnizacion => {

                                        switch (incidenciaIndemnizacion.codigo_incidencia.charAt(0)) {
                                            case 'P':
                                                let complemento = {
                                                    codigo : incidenciaIndemnizacion.codigo_incidencia,
                                                    nombre : incidenciaIndemnizacion.nombre_incidencia,
                                                    total  : incidenciaIndemnizacion.total
                                                }
                                                if(parseFloat(incidenciaIndemnizacion.total) != 0){
                                                    empleado.itemsPercepcionesIndemnizacion.push(complemento);
                                                }
                                                break;
                                            case 'D':
                                                let complemento2 = {
                                                    codigo : incidenciaIndemnizacion.codigo_incidencia,
                                                    nombre : incidenciaIndemnizacion.nombre_incidencia,
                                                    total  : incidenciaIndemnizacion.total
                                                }
                                                if(parseFloat(incidenciaIndemnizacion.total) != 0){
                                                    empleado.itemsDeduccionesIndemnizacion.push(complemento2);
                                                }
                                                break;
                                            case 'O':
                                                let complemento3 = {
                                                    codigo : incidenciaIndemnizacion.codigo_incidencia,
                                                    nombre : incidenciaIndemnizacion.nombre_incidencia,
                                                    total  : incidenciaIndemnizacion.total
                                                }
                                                if(parseFloat(incidenciaIndemnizacion.total) != 0){
                                                    empleado.itemsOtrosPagosIndemnizacion.push(complemento3);
                                                }
                                                break;  
                                            case 'I':
                                                let complemento4 = {
                                                    codigo : incidenciaIndemnizacion.codigo_incidencia,
                                                    nombre : incidenciaIndemnizacion.nombre_incidencia,
                                                    total  : incidenciaIndemnizacion.total
                                                }
                                                if(parseFloat(incidenciaIndemnizacion.total) != 0){
                                                    empleado.itemsInformativosIndemnizacion.push(complemento4);
                                                }
                                                break;  
                                            default:
                                                break;
                                        }

                                    })
                                }


                                
                                

                                if(parseFloat(empleado.subsidio) != 0){
                                    empleado.itemsOtrosPagos.push({
                                        codigo : 'O999',
                                        nombre : 'Subsidio',
                                        total  : empleado.subsidio
                                    });
                                }
                                if(parseFloat(empleado.isr) != 0){
                                    empleado.itemsDeducciones.push({
                                        codigo : 'D998',
                                        nombre : 'ISR',
                                        total  : empleado.isr
                                    });
                                }
                                if(parseFloat(empleado.imss) != 0){
                                    empleado.itemsDeducciones.push({
                                        codigo : 'D999',
                                        nombre : 'IMSS',
                                        total  : empleado.imss
                                    });
                                }
                            })
                        }

                        this.itemsEmpleados = formatEmpleados;

                        setTimeout(function(){ document.getElementById("btnModalCalculoTabla").click(); }, 100);
                        this.isLoadingCalculoTabla = false;
                        this.btnConsultar       = false;
                        this.invaliBtnGuardar   = false;
                        this.btnCerrar          = false;
                    })
                    .catch (err => {
                        console.log(err);
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                        this.cerrarModalTabla();
                    })
                }
            })
        },
        cerrarModalTabla(){
            this.dialogCalculoTabla = false;
            this.btnConsultar       = false;
            this.invaliBtnGuardar   = false;
            this.btnCerrar          = false; 
            this.tamModal           = "700px";   
        },
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        numParse(num){
            return parseFloat(Math.round(num * 100) / 100).toFixed(2);
        },
        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato AAAA-MM-DD
         * @param {string} tipo string con valor 'modal' o 'tabla'
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha, tipo='tabla') {
            if(fecha == null){
                return;
            }
            else{
                let fecha2 = fecha.split("-");
                if(tipo == 'modal'){
                    return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
                }else{
                    return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
                }
            }
            
        },
        formatoFecha(fecha) {
            if(fecha == null || fecha == undefined){
                return;
            }
            let fecha2 = fecha.split("-");
            return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
        },
        redondearNumero(numero){
            return Math.round(numero*100)/100
        },
        netoPago(neto_pagar = 0, indemnizacion = 0){
            return this.formatNum(parseFloat(neto_pagar) + parseFloat(indemnizacion));
        },
        toggleAllEmpledos () {
            this.$nextTick(() => {
                if (this.likesAllEmpleados) {
                    this.modelo_empleados = [];
                } else {
                    this.modelo_empleados = [];
                    if(this.items_empleados.length > 0 ){
                        for (let empleado = 0; empleado < this.items_empleados.length; empleado++) {
                            this.modelo_empleados.push(this.items_empleados[empleado].id);
                        }
                    }
                }
            })
        },
        detallePrecalculo(data){
            this.$session.remove("detallePrecalculoAF");
            this.$session.set('detallePrecalculoAF',data);
            this.$router.push('/detalle');
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresRP = [];
        },
    },
    mounted(){

    },
    created() {
        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        this.urlAvatar = urlImg.getURL();
        this.listar();
        this.getPeriodicidad();
        this.getTipoNominaSAT();

        if(this.rol == 'root'){
            this.getClientes();
        }
        else if(this.rol == 'admin'){
            this.cliente_value = this.datosLogin.cliente_id;
        }
        else{
            this.cliente_value = this.datosLogin.cliente_id;
            this.empresa_value = this.datosLogin.empresa_id;
        }
    },
    updated() {
        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.width   = "310px";
        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.paddingRight   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.paddingLeft   = "8px";

        if(document.querySelector('#tableScrollSimple')){
            document.querySelector('#tableScrollSimple tbody').style.maxHeight    = "none";
            document.querySelector('#tableScrollSimple tbody').style.minWidth     = "100%";
            document.querySelector('#tableScrollSimple tbody').style.background   = "#fff";
        }
    },
    
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    #tableScrollSimple tbody{
        max-height:none !important;
        min-width: 100% !important;
        background-color: #fff !important;
    }
    .tbl-child tbody td:first-child {
        padding-left: 35px !important;
        width: 320px;
    }
    .titleModalTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '600';
        font-size: 18px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .textTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '500';
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .textAguinaldo{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #828282 !important;
        letter-spacing: -0.2px;
        padding-bottom: 20px;
    }
    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }

    .btnContrats.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        height: 36px;
        color: #FFFFFF;
        text-transform: capitalize;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
    }
    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

    #btnModalErrores{
        display: none;
    }

</style>
